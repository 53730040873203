
import {config} from '@/utils'

import homeTitlepc from '@/components/home/homeTitlepc.vue'
import headerBanner from '../../home/banner.vue'
import Bottoms from '../../home/bottoms.vue'

export default {
    name: 'business',
    components: {
        homeTitlepc,
        headerBanner,
        Bottoms
        
    },

    mounted () {
     
    },

    created(){
       

    },

    methods: {
        contactKefu(){
            // source(来源) 1:人是云    2:识权识美
            if(localStorage.getItem('_RedirectData') !=null){
                let ac=JSON.parse(localStorage.getItem('_RedirectData'))
                let url=`${config.kefu}/frontend?source=2`+'&token='+ac.token+'&usertype='+ac.usertype+'&chat_role=1'
                window.open(url)
            }else{
                let url=`${config.kefu}/frontend?source=2`+'&chat_role=1'
                window.open(url)
            }


        }

    },

    data () {
        return {
            config,
            works1:[
                {
                    img:require('./images/s1.png'),
                    title:require('./images/t1.png'),
                    name:'被侵权无处可查',
                    show:'缺少平台资源，版权运营时缺乏多维度的平台渠道'
                },
                {
                    img:require('./images/s2.png'),
                    title:require('./images/t2.png'),
                    name:'版权监管难',
                    show:'无法保留版权权力，缺乏议价权时以出让权属利益为代价'
                },
                {
                    img:require('./images/s3.png'),
                    title:require('./images/t3.png'),
                    name:'无法及时保留记录',
                    show:'无法保留版权权力，沟通对接等耗费大量精力'
                },
            ],
            works2:[
                {
                    img:require('./images/p1.png'),
                    name:'保证版权权力仅进行代理服务',
                },
                {
                    img:require('./images/p2.png'),
                    name:'帮助版权作品清晰定位运营方向',
                },
                {
                    img:require('./images/p3.png'),
                    name:'制定长期稳定运营计划',
                },
                {
                    img:require('./images/p4.png'),
                    name:'利用多元化平台整合资源',
                },
                {
                    img:require('./images/p5.png'),
                    name:'版权保护、版权监测、版权维权一站式服务',
                },
            ]
           
            
        }
    }
}
